@font-face {
  font-family: "Gill Sans";
  src: url("../assets/fonts/GillSans.eot");
  src: local("Gill Sans"), local("GillSans"),
    url("../assets/fonts/GillSans.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/GillSans.woff2") format("woff2"),
    url("../assets/fonts/GillSans.woff") format("woff"),
    url("../assets/fonts/GillSans.ttf") format("truetype"),
    url("../assets/fonts/GillSans.svg#GillSans") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cormorant Garamond";
  src: url("../assets/fonts/CormorantGaramond-Regular.eot");
  src: local("Cormorant Garamond Regular"), local("CormorantGaramond-Regular"),
    url("../assets/fonts/CormorantGaramond-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/CormorantGaramond-Regular.woff2") format("woff2"),
    url("../assets/fonts/CormorantGaramond-Regular.woff") format("woff"),
    url("../assets/fonts/CormorantGaramond-Regular.ttf") format("truetype"),
    url("../assets/fonts/CormorantGaramond-Regular.svg#CormorantGaramond-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cormorant Garamond";
  src: url("../assets/fonts/CormorantGaramond-Italic.eot");
  src: local("Cormorant Garamond Italic"), local("CormorantGaramond-Italic"),
    url("../assets/fonts/CormorantGaramond-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/CormorantGaramond-Italic.woff2") format("woff2"),
    url("../assets/fonts/CormorantGaramond-Italic.woff") format("woff"),
    url("../assets/fonts/CormorantGaramond-Italic.ttf") format("truetype"),
    url("../assets/fonts/CormorantGaramond-Italic.svg#CormorantGaramond-Italic")
      format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Cormorant Garamond";
  src: url("../assets/fonts/CormorantGaramond-Bold.eot");
  src: local("Cormorant Garamond Bold"), local("CormorantGaramond-Bold"),
    url("../assets/fonts/CormorantGaramond-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/CormorantGaramond-Bold.woff2") format("woff2"),
    url("../assets/fonts/CormorantGaramond-Bold.woff") format("woff"),
    url("../assets/fonts/CormorantGaramond-Bold.ttf") format("truetype"),
    url("../assets/fonts/CormorantGaramond-Bold.svg#CormorantGaramond-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gibsons Co";
  src: url("../assets/fonts/GibsonsCo.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat Light";
  src: url("../assets/fonts/Montserrat-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/*Header*/
header {
  position: fixed;
  border-bottom: 1px solid #cacaca;
  background-color: #ffffff;
  width: 100%;
  letter-spacing: normal;
  z-index: 1;
}
.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.main-black-bar .top-banner {
  background-color: #24262c;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
}
div[class*="col-"] {
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
}
.main-black-bar .top-banner .wrapper {
  width: calc(100% - 75px);
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
}
.main-black-bar .marquee {
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}
.main-black-bar .top-banner .wrapper .marquee span {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 11px;
  color: #a7a8ac;
  line-height: 43px;
  letter-spacing: 1.2px;
  display: inline-block;
}
.main-black-bar .top-banner a,
.main-black-bar .top-banner a:hover {
  color: #a7a8ac;
}
.main-black-bar .top-banner .xCloseTopBanner {
  position: absolute;
  right: 30px;
  margin-left: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.main-black-bar .close-button {
  position: relative;
  border-left: 3px solid #606c76;
  transform: rotateZ(45deg);
  height: 14px;
  display: inline-block;
  border-radius: 20%;
}
.main-black-bar .close-button:after {
  content: "";
  position: absolute;
  border-right: 3px solid #606c76;
  top: 0;
  height: 14px;
  left: -3px;
  transform: rotate(-90deg);
  border-radius: 20%;
}
header .container-header .header-space {
  margin-bottom: 26px;
  padding: 0 79px;
}
header .container-header {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  position: relative;
  height: 106px;
}
header .container-header .wrapper-mobile-header {
  padding-top: 14px;
  padding-bottom: 2px;
  position: relative;
}
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}
.col-xs-3 {
  max-width: 25%;
  flex: 0 0 25%;
  -ms-flex: 0 0 25%;
}
.col-xs-6 {
  max-width: 50%;
  flex: 0 0 50%;
  -ms-flex: 0 0 50%;
}
.col-xs-12 {
  max-width: 100%;
  flex: 0 0 100%;
  -ms-flex: 0 0 100%;
}
header .header-icons {
  width: 25px;
  height: 25px;
}
header .container-header .wrapper-mobile-header .header-mobile-logo {
  width: 152px;
  height: 25px;
  margin: 0 auto;
  display: block;
}
header .container-header .wrapper-mobile-header .bag-mobile-logo {
  float: right;
}
header .header-icons {
  width: 25px;
  height: 25px;
}
header ul.primary-menu {
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
  flex-grow: 1;
}
header ul {
  margin: 0;
}
header ul.primary-menu li,
header ul.primary-menu a {
  display: inline;
  font-size: 17px;
  padding: 10px 0;
  text-decoration: none;
}
header ul li,
header ul a {
  list-style: none;
  text-transform: uppercase;
  color: #222222;
  font-family: "Cormorant Garamond", serif;
  font-weight: 600;
  margin-bottom: 0;
}
header .header-logo {
  padding-top: 24px;
  padding-left: 8%;
  padding-right: 8%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
header .inside-left-menu {
  position: fixed;
  background-color: #ffffff;
  box-sizing: border-box;
  min-width: 495px;
  height: 100%;
  margin-left: -115%;
  transition: margin-left 1s, top 1s ease 0s;
  border-top: 0;
  padding-bottom: 135px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
header .inside-left-menu:before {
  background-color: #ffffff;
  position: absolute;
  left: -999%;
  width: 1000%;
  height: 100%;
  content: "";
}
header .inside-left-menu .internal-structure {
  padding: 77px 60px 20px 92px;
  position: relative;
  scrollbar-width: none;
}
header .inside-left-menu .internal-structure .close-button-menu {
  display: none;
  top: 30px;
  right: 21px;
  z-index: 4;
  width: 20px;
  height: 20px;
  position: absolute;
  background-repeat: no-repeat;
  background-size: 70%;
  cursor: pointer;
  background-position: right;
}
header .close-button-menu,
.p-modal-close {
  background-image: url("../assets/old-game/close-button-mennu.svg") !important;
}
header .inside-left-menu .internal-structure .back-button {
  display: none;
}
header .inside-left-menu .internal-structure .internal-primary-menu {
  width: 375px;
}
header .inside-left-menu .internal-structure .internal-primary-menu ul {
  padding-top: 4px;
}
header .inside-left-menu .internal-structure .internal-primary-menu li {
  font-size: 30px;
  line-height: 28px;
  margin-bottom: 13px;
  height: 30.47px;
  cursor: pointer;
}
header .inside-left-menu .internal-structure .internal-primary-menu li,
header .inside-left-menu .internal-structure .internal-primary-menu a,
header .inside-left-menu .internal-structure .internal-primary-menu span {
  font-size: 30px;
  margin-bottom: 13px;
  line-height: 28px;
  letter-spacing: inherit;
  font-size: 30px;
}
header
  .inside-left-menu
  .internal-structure
  .internal-primary-menu
  li
  .angle-right {
  opacity: 0;
  margin-left: 0;
  margin-bottom: 0;
}
header
  .inside-left-menu
  .internal-structure
  .internal-primary-menu
  li
  .internal-submenu {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  display: none;
  min-width: 478px;
  box-sizing: border-box;
  padding: 68px 46px 60px 0px;
  height: 100vh;
  transition: left 1s ease-out;
}
header
  .inside-left-menu
  .internal-structure
  .internal-primary-menu
  li
  .internal-submenu
  ul {
  margin: 0;
  padding-left: 0px;
  padding-top: 8px;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: height 1.5s ease-out, opacity 1s;
  scrollbar-width: none;
}
header
  .inside-left-menu
  .internal-structure
  .internal-primary-menu
  li
  .internal-submenu
  ul
  li
  a,
header
  .inside-left-menu
  .internal-structure
  .internal-primary-menu
  li
  .internal-submenu
  ul
  li
  span {
  transition: padding-left 0.5s;
}
header .inside-left-menu .internal-structure .internal-account-menu {
  margin-top: 64px;
}
header .inside-left-menu .internal-structure .internal-account-menu ul,
header .inside-left-menu .internal-structure .internal-secondary-menu ul {
  line-height: 28px;
  font-size: 25px;
}
header .inside-left-menu .internal-structure .internal-account-menu ul li,
header .inside-left-menu .internal-structure .internal-secondary-menu ul li {
  margin-bottom: 2px;
}
header .inside-left-menu .internal-structure .internal-account-menu ul li a,
header .inside-left-menu .internal-structure .internal-secondary-menu ul li a {
  font-size: 17px;
  line-height: 26px;
  text-align: left;
  letter-spacing: inherit;
}
header .inside-left-menu .internal-structure .internal-secondary-menu {
  margin-top: 32px;
}
header .inside-right-menu {
  opacity: 1;
}
#login-register {
  position: fixed;
  height: 100%;
  right: 0;
  top: 0;
  background: #fff;
  transition: width 1s;
  z-index: 999;
}
header .inside-right-menu #bag,
header .inside-right-menu #login-register,
header .inside-right-menu #welcome-back,
header .inside-right-menu .container-checkout-register,
header .inside-right-menu .search-no-items,
header .inside-right-menu .search-with-items {
  margin-right: -100%;
  transition: margin-right 1s, top 1s ease 0s, width 0.5s ease-in-out;
  width: 0%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
#login-register #wrapper-login-register {
  position: relative;
  padding: 0 9.4rem;
  margin-top: 45px;
  max-width: 720px;
}
header .inside-right-menu #bag .close-button,
header .inside-right-menu #login-register .close-button,
header .inside-right-menu #welcome-back .close-button,
header .inside-right-menu .container-checkout-register .close-button,
header .inside-right-menu .search-no-items .close-button,
header .inside-right-menu .search-with-items .close-button {
  display: none;
}
.angle-right.angle-left {
  transform: rotate(180deg);
}
header .angle-right {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  width: 16px;
  height: 20px;
  opacity: 1;
  margin-left: 6px;
  transition: opacity 0.5s, margin-left 0.5s;
}
.angle-right {
  background-image: url("../assets/old-game/arrow-right.png");
}
.angle-right {
  display: inline-block;
  width: 16px;
  height: 20px;
  background-repeat: no-repeat;
}
.go-back {
  margin-top: -17px;
  position: absolute;
  cursor: pointer;
  margin-left: -3px !important;
}
#login-register #wrapper-login-register .p-modal-close {
  right: 94px;
  margin-top: -17px;
}
.close-button .p-modal-close {
  color: #222222;
  font-size: 28px;
  cursor: pointer;
  line-height: 1.1;
  margin-top: 31px;
  position: absolute;
  right: 4rem;
  z-index: 4;
  width: 20px;
  height: 20px;
  background-image: url("../assets/old-game/close-button-mennu.svg");
  background-repeat: no-repeat;
  background-size: 70%;
  background-position: right;
  font-family: "Montserrat";
  font-weight: 300;
}
.close-button .p-modal-close {
  color: #222222;
  font-size: 28px;
  cursor: pointer;
  line-height: 1.1;
  margin-top: 31px;
  position: absolute;
  right: 4rem;
  z-index: 4;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 70%;
  background-position: right;
  font-family: "Montserrat";
  font-weight: 300;
}
.login-register-header {
  overflow: hidden;
}
.login-register-header .as-h3-custom {
  font-family: "Cormorant Garamond", serif;
  font-weight: 600;
}
.login-register-header h3,
.login-register-header .as-h3-custom {
  font-size: 21px;
  line-height: 28px;
  letter-spacing: 0;
  text-transform: uppercase;
  margin: 27px 0 10px 0;
}
.login-register-header p {
  margin: 0;
  line-height: 28px;
}
.mt-30 {
  margin-top: 30px;
}
form:not(#filterForm) label:not(.not-apply) {
  font-family: "Montserrat" !important;
  color: #222 !important;
  font-size: 11px !important;
  font-weight: 500 !important;
  letter-spacing: 1.2px !important;
  text-transform: uppercase !important;
}
#login-registro-previo-form .d-flex,
#ciam_login_form .d-flex {
  display: block !important;
}
.d-flex {
  display: flex;
  display: -ms-flexbox !important;
  display: flex !important;
}
#login-registro-previo-form .d-flex input,
#ciam_login_form .d-flex input {
  width: calc(100% - 70px);
  float: left;
  margin-top: 0;
}
.form-login-register .custom-input {
  width: 90%;
  border-right: none;
  padding: 0px 40px 0px 12px;
}
input.custom-input {
  color: #555;
}
/* span.input,
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
textarea,
select,
input {
  margin-bottom: 0;
  min-height: 46px;
  border-radius: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -webkit-transition: color 0.25s;
  transition: color 0.25s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 300;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  width: 100%;
  box-shadow: none;
  box-sizing: inherit;
  padding-left: 12px;
  padding-right: 14%;
} */
#login-registro-previo-form .d-flex button,
#ciam_login_form .d-flex button {
  height: 46px;
  width: 60px;
}
.form-login-register .btn-arrow {
  background-color: #fff;
  border: 1px solid #ccc;
  border-left: none;
  width: 10%;
  outline: 0;
  min-width: 60px;
  border-radius: 0;
  padding: 0;
  margin-bottom: 0;
}
.form-login-register .btn-arrow .arrow-img {
  margin: auto;
  max-width: 10px;
  display: block;
  height: auto;
}
.mb-25 {
  margin-bottom: 25px;
}
.mt-70 {
  margin-top: 70px;
}
.mb-25 {
  margin-bottom: 25px;
}
#bag .wrapper-bag {
  position: relative;
  padding: 0 9.4rem;
  max-width: 720px;
}
#bag .wrapper-bag .p-modal-close {
  right: 94px;
}
#bag .wrapper-bag .bag-header {
  overflow: hidden;
}
#bag .wrapper-bag .bag-header .line-header {
  border-bottom: 3px solid rgba(151, 151, 151, 0.35);
}
.bag-header .h2-custom-bag {
  font-weight: 600;
}
.bag-header h2,
.bag-header .h2-custom-bag {
  padding-top: 65px;
  font-size: 21px;
  text-transform: uppercase;
  line-height: 28px;
  letter-spacing: 0;
  margin-bottom: 0;
}
.bag-header .line-header {
  border-bottom: 3px solid rgba(151, 151, 151, 0.35);
}
.f-coro {
  font-family: "CormorantGaramond";
}
#sideCartContainer {
  position: relative;
}
#sideCartContainer .productContainer {
  position: relative;
  margin-bottom: 35px;
  padding-bottom: 15px;
}
.your-cart-empty {
  padding: 97px 0 80px 0px;
  font-size: 15px;
  color: #222;
  font-family: "Montserrat";
  font-weight: 300;
  text-align: center;
}
.button-base.button-box-black-color,
button.button-box-black-color {
  font-family: "Montserrat";
  font-weight: 300;
  color: #fff;
  border: 1px solid #000;
  background-color: #000;
}
#login-register {
  position: fixed;
  height: 100%;
  right: 0;
  top: 0;
  background: #fff;
  transition: width 1s;
  z-index: 999;
}
#bag {
  position: fixed;
  height: 100%;
  right: 0;
  top: 0;
  background: #fff;
  z-index: 999;
  margin: 0 auto;
  overflow: auto;
  transition: margin-right 1s, top 1s ease 0s, width 0.5s ease-in-out;
  width: 0%;
  -ms-overflow-style: none;
}
body.hasBanner .window-drch-izq.window-small {
  top: 150px;
}
.window-drch-izq.window-small.window-drch-izq {
  width: 40%;
  top: 107px;
  padding-bottom: 90px;
}
.window-drch-izq {
  position: fixed;
  right: -100%;
  width: 50%;
  top: 0;
  background: #fff;
  transition: width 1s;
  z-index: 999;
  height: 100%;
  transition: right 1s, top 1s ease 0s, width 0.5s ease-in-out;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
#window-search .close-button {
  display: none;
}
.window-drch-izq .close-button-menu {
  top: -15px;
  right: -4px;
  cursor: pointer;
  line-height: 1.1;
  position: relative;
  z-index: 144;
  width: 20px;
  height: 20px;
  background-image: url("../assets/old-game/close-button-mennu.svg");
  background-repeat: no-repeat;
  background-size: 70%;
  float: right;
  display: block;
}
.window-drch-izq.window-small.window-drch-izq .window-drch-izq-content {
  padding-right: 0;
}
.window-drch-izq .window-drch-izq-content {
  position: relative;
  padding: 0 9.4rem;
  margin-top: 45px;
  max-width: 720px;
}
.search-form {
  padding-bottom: 0px;
  padding-right: 5px;
  padding-left: 0px;
  border-bottom: 1px solid #979797;
  overflow: inherit;
  display: table;
  line-height: normal;
  position: relative;
  height: 55px;
}
.search-form .search-form-input {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-top: 8px;
}
.search-form .search-form-input .search-input {
  display: inline-block;
  float: none;
  box-shadow: none;
  margin-bottom: 1px;
  border: none;
  width: calc(50% + 30vw);
  text-transform: uppercase;
  font-size: 27px;
  font-family: "Conv_CormorantGaramond";
  color: #000;
  outline: none;
  padding-left: 15px;
  width: 100%;
}
.search-form .search-form-input .search-input-plp-close-icon {
  border: 1px solid transparent;
  background-color: transparent;
  width: 19.8px;
  height: 19.8px;
  position: absolute;
  right: 50px;
  bottom: calc(50% - 18px);
  margin-bottom: 0;
  padding: 2px;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
}
.search-input-plp-close-icon {
  position: absolute !important;
  right: 30px !important;
}
button,
dd,
dt,
li {
  margin-bottom: 1rem;
}
.button-base {
  width: 100%;
  display: block;
  padding: 15px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 11px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  line-height: normal;
  cursor: pointer;
}
.search-form .search-form-input .search-form-btns-hand-lens {
  width: 22px;
  height: 22px;
  background-image: url("../assets/old-game/search.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  right: 0;
  position: absolute;
  top: calc(50%);
}
.search-form-btns-hand-lens {
  width: 22px;
  height: 22px;
  background-image: url("../assets/old-game/search.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  right: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.search-form .search-form-input .search-form-btns-hand-lens {
  background-image: url("../assets/old-game/search.svg") !important;
}
#blockPuigsearch .search-wrapper .top-search-title {
  font-family: "CormorantGaramond";
  font-size: 21px;
  line-height: 28px;
  color: #222;
  letter-spacing: 0;
  text-transform: uppercase;
  margin-bottom: 15px;
  margin-top: 30px;
  display: table;
  float: left;
  width: 100%;
}
#blockPuigsearch .search-wrapper .list-top-search-items {
  list-style: none;
  padding-left: 0;
  margin-bottom: 5em;
  margin-top: 0;
  clear: both;
}
#blockPuigsearch .search-wrapper .list-top-search-items li a {
  display: block;
  padding-bottom: 5px;
  text-transform: uppercase;
  font-size: 17px;
  color: #222;
  text-decoration: none;
  font-family: "CormorantGaramond";
  letter-spacing: inherit;
  line-height: 28px;
}

/*Footer*/
footer {
  background-color: #fff;
}
footer .footer-principal {
  padding-top: 50px;
  border-top: 1px solid rgba(151, 151, 151, 0.35);
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}
.footercountry {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  display: inline-block;
  position: relative;
  top: 0;
}
footer ul li {
  margin-bottom: 0;
}
.footerselectcustom {
  width: 290.5px;
}
.main-container .row-space,
footer .row-space {
  padding: 0 79px;
}
footer .newsletter {
  padding-bottom: 50px !important;
}
.newsletter {
  border-bottom: 1px solid rgba(151, 151, 151, 0.35);
  padding-bottom: 50px;
  margin-bottom: 50px;
  text-align: center;
}
.home-newsletter-title h2.block-title {
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.4px;
  margin-top: 24px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.home-newsletter-title h2 {
  font-family: "Cormorant Garamond", serif;
  font-size: 44px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.05;
  letter-spacing: 0.4px;
  text-align: center;
  color: #222222;
  text-transform: uppercase;
}
.home-newsletter-title p {
  margin-bottom: 38px;
}
.newsletter p {
  text-align: center;
  margin-top: 10px;
  font-size: 15px;
}
.hide {
  display: none;
}
footer p {
  font-size: 13px;
  line-height: 24px;
  margin-top: 0px;
  margin-bottom: 0;
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
}
.newsletter .flex {
  display: flex;
}
.hide-label {
  visibility: hidden;
  display: block;
  height: 0;
  width: 0;
}
.newsletter input {
  border: 1px solid #acacac;
  font-size: 15px;
  border-right: none;
  display: inline-block;
  padding: 12px 26px;
  width: 80%;
}
.newsletter button {
  margin-bottom: 0;
  background-color: #fff;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
  border: 1px solid #000;
  outline: none;
  width: 110px;
  padding-left: 0;
  padding-right: 0;
}
.newsletter button:hover {
  border-color: #000;
  background-color: #000;
  color: #fff;
}
span[data-error] .error {
  color: #d84747;
  display: block;
}
.newsletter a {
  text-align: center;
  margin-top: 10px;
  font-family: "Montserrat";
  font-weight: 300;
  line-height: 28px;
  padding-bottom: 2px;
  border-bottom: 1px solid #222;
  letter-spacing: 0;
}
.newsletter .formErrorMessage {
  color: #d84747;
  width: 100%;
  clear: both;
  line-height: 1.4;
  margin-top: 10px;
  text-align: center;
}
.dnone {
  display: none !important;
}
.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
.box-field-register-checkout .select2-container {
  width: 100% !important;
}
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}
footer .footer-principal .select2-container .select2-selection {
  padding-top: 5px;
}
.box-field-register-checkout .select2-container .select2-selection {
  height: 46px;
  padding-top: 9px;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 0;
}
.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}
.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container--default .select2-selection--single {
  border-radius: 0px !important;
}
.select2-selection.select2-selection--single {
  text-transform: uppercase;
  font-size: 13px;
}
.box-field-register-checkout
  .select2-container
  .select2-selection
  .select2-selection__rendered {
  padding-left: 12px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
footer .footer-principal .select2-container .select2-selection__rendered span {
  font-size: 13px;
  font-family: "Montserrat";
  font-weight: 300;
}
.box-field-register-checkout .select2-selection__rendered span {
  display: flex;
  align-items: center;
  margin-top: 3px;
}
.box-field-register-checkout
  .select2-container
  .select2-selection
  .select2-selection__arrow {
  top: 50%;
  transform: translateY(-50%);
  right: 2%;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}
.box-field-register-checkout
  .select2-container
  .select2-selection
  .select2-selection__arrow
  b {
  border: 0px solid #fff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  width: 20px;
  height: 10px;
  left: 2%;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  margin-top: -4px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container .select2-selection .select2-selection__arrow b {
  background-image: url("../assets/old-game/arrow-down.svg");
}
footer .socialiconsaccess-content {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  max-width: 300px;
}
footer .socialiconsaccess-content > div {
  width: 100%;
}
.footer-principal .addtoany_list {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  width: 79%;
  margin: 0px auto;
}
.footer-principal .addtoany_list a {
  font-size: 19px !important;
}
footer .services-footer {
  padding-left: 95px;
}
footer h3 {
  font-family: "Cormorant Garamond", serif;
  font-weight: 600;
  letter-spacing: normal;
  font-size: 21px;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 0;
}
footer .footer-collapsed {
  display: none;
}
footer ul {
  padding-top: 0px;
  margin-bottom: 0;
  list-style-type: none;
}
footer ul li span {
  font-size: 16px;
}
footer .services-footer li a,
footer .about-us-footer li a,
footer .profiling-footer li a {
  text-transform: uppercase;
  font-family: "CormorantGaramond";
  font-weight: normal;
  line-height: 28px;
}
footer a:hover,
header a:hover {
  color: #606c76;
}
footer ul li span a {
  font-family: "CormorantGaramond";
  font-weight: normal;
  text-transform: uppercase;
}
footer .about-us-footer {
  padding-left: 50px;
}
[data-show] .accordion-arrow-footer {
  transform: rotate(-180deg) !important;
  -webkit-transform: rotate(-180deg) !important;
}
[data-show] .accordion-arrow-footer,
[data-show] .accordion-arrow,
[data-show] .angle-right {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
[data-show].isvisisble .accordion-arrow-footer,
[data-show].isvisisble .accordion-arrow {
  transform: translateY(-50%);
  transform: rotate(0deg) !important;
  -webkit-transform: rotate(0deg) !important;
}
footer .accordion-arrow-footer {
  display: none;
  background-image: url("../assets/old-game/arrow_mobile_up.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  margin-top: 2px;
  width: 13px;
  height: 7px;
  float: right;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
footer .profiling-footer {
  padding-left: 5px;
}
footer .find-proyect {
  text-transform: uppercase;
  padding-bottom: 2px;
  border-bottom: 1px solid #222;
  line-height: initial;
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 11px;
  font-weight: 500;
  font-family: "Montserrat";
}
footer .second-footer {
  border-top: 1px solid rgba(151, 151, 151, 0.35);
  margin-top: 50px;
}
footer .second-footer ul {
  margin-top: 25px;
}
footer .second-footer ul li {
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 13px;
  display: inline-block;
  padding: 10px 23px 10px 0;
}
footer .second-footer ul li a {
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 13px;
  display: inline-block;
  letter-spacing: normal;
}
footer .second-footer p {
  padding-top: 10px;
  padding-bottom: 20px;
  margin-top: 25px;
  padding-left: 61px;
  text-align: right;
}
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.footerlanguageselect {
  position: relative;
}
footer .select2-results__options {
  position: absolute;
  top: 100%;
  width: 100%;
  background: #fff;
  border: 1px solid #ccc;
  border-top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: none;
}
footer .select2-results__options.active {
  display: block;
}
footer .select2-results__options li {
  padding: 10px 12px;
}
footer .select2-results__options li span {
  display: flex;
  align-items: center;
  font-family: "Montserrat";
  font-size: 13px;
}
footer .select2-results__options li.selected {
  background: #b8b8b8;
  color: #fff;
}
.stop-game {
  padding-top: 215px;
  position: relative;
  z-index: 1;
}
.stop-game .container {
  padding: 15px 85px;
}
.banner {
  text-align: center;
}
.stop-text {
  max-width: 322px;
  margin: auto;
  font-size: 23px;
  padding: 30px 30px 60px;
  line-height: 1.5;
  text-align: center;
  color: #436749;
}
.mobile {
  display: none;
  margin: -15px -15px 0;
}
.remove-header .headercloud {
  display: none;
}
.remove-header .main {
  padding-top: 30px;
}
.italic {
  font-style: italic;
}
.bold {
  font-weight: bold;
  margin: 0 -10px;
}
.arrows {
  min-width: 250px;
  bottom: 64px;
  left: 50%;
  white-space: normal;
  transform: translateX(-50%);
  padding: 0 50px;
}
.arrow-text {
  display: block;
  max-width: 150px;
  margin: auto;
}
.redirect-text {
  padding-top: 0;
}
.landingimage,
.preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
}
.landingimage {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("../assets/old-game/landing.jpg") no-repeat 50% 50%;
  background-size: cover;
  opacity: 0;
  animation: fadein 1s forwards;
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.header_logoa {
  position: absolute;
  top: 170px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
}
.header_img {
  width: 350px;
  height: auto;
  opacity: 1;
  transition: opacity 0.4s;
}
.header_logoa:hover .header_img {
  opacity: 0.7;
}
.content_wrap {
  position: relative;
  overflow: hidden;
  background: #fad2c9;
}
.footerimage {
  position: absolute;
  width: 100%;
  height: 303px;
  bottom: 0;
  left: 0;
  background: url("../assets/old-game/footer.png") no-repeat;
  background-size: cover;
}
.content_wrap:before,
.content_wrap:after {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  background: url("../assets/old-game/left.png") no-repeat 100% 100%;
  bottom: 0;
  right: 100%;
  background-size: cover;
  z-index: 1;
  transform: translateX(56%);
}
.content_wrap:after {
  background: url("../assets/old-game/right.png") no-repeat 0% 100%;
  background-size: cover;
  left: 100%;
  right: auto;
  transform: translateX(-56%);
}
.catcher_sec {
  padding-top: 15px;
}
.catcher_img {
  width: 135px;
  height: auto;
}
.landingwrap {
  position: relative;
  z-index: 1;
}
.finishpopup {
  padding: 20px 30px;
  max-width: 247px;
  margin: auto;
  background: #fffdec;
  color: #436749;
  font-style: normal;
  font-size: 22px;
}
.game-result .limit-text {
  max-width: 320px;
  margin: auto;
  font-size: 23px;
}
.redirect-text + .nowin-wrap + .limit-text {
  max-width: 510px;
  margin-top: 8px;
}
.redirect-text + .nowin-wrap + .limit-text .bold {
  font-weight: normal;
  margin: 0;
}
.game-result .button {
  max-width: 260px;
}
.termspop .dialog-position {
  text-align: center;
  padding: 80px 54px;
}
.termspopclose {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
}
.termspopclose:before,
.termspopclose:after {
  content: "";
  position: absolute;
  width: 3px;
  height: 100%;
  top: 0;
  left: 8px;
  background: #000;
  transform: rotate(45deg);
}
.termspopclose:after {
  transform: rotate(-45deg);
}
.big-title.termstitle {
  padding-bottom: 40px;
}
.termstext {
  font-size: 20px;
}
.gift_wrapper {
  position: absolute;
  bottom: 44px;
  right: 116px;
  padding: 8px 38px;
  z-index: 2;
}
.gift_wrapper::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url("../assets/old-game/score.png") no-repeat;
  background-size: cover;
}
.preloader {
  z-index: 99999999;
  background-color: #101116;
  transition: transform 0.8s;
}
.preloader.remove {
  transform: translateY(-120%);
}
header {
  position: relative;
  /* z-index: 99; */
}
#form-add,
#form-add input[type="text"] {
  opacity: 0;
  font-size: 0;
  min-height: 0;
  border: none;
}
.van img {
  height: 166px;
}

@media (min-width: 576px) {
  .col-sm-4 {
    max-width: 33.33333%;
    flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
  }
}
@media (min-width: 767px) {
  .inside-left-menu.shop-menu .internal-structure {
    height: 100%;
  }
  .internal-structure-container-full {
    overflow-y: scroll;
    height: 100%;
    scrollbar-width: none;
  }
}
@media (min-width: 768px) {
  .search-form .search-form-input .search-input-plp-close-icon {
    bottom: calc(50% - 16px);
  }
  .search-form .search-form-input .search-form-btns-hand-lens {
    top: calc(50% - 2px);
  }
}
@media (min-width: 992px) {
  .col-lg-4 {
    max-width: 33.33333%;
    flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
  }
  #login-register {
    width: 40%;
  }
  .col-lg-10 {
    max-width: 83.33333%;
    flex: 0 0 83.33333%;
    -ms-flex: 0 0 83.33333%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }
  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
    -ms-flex: 0 0 50%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-3 {
    max-width: 25%;
    flex: 0 0 25%;
    -ms-flex: 0 0 25%;
  }
  .services-footer h3,
  .about-us-footer h3,
  .profiling-footer h3 {
    pointer-events: none;
  }
  footer .footer-collapsed {
    display: block !important;
  }
  .col-lg-9 {
    max-width: 75%;
    flex: 0 0 75%;
    -ms-flex: 0 0 75%;
  }
}
@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
  .search-form .search-form-input .search-form-btns-hand-lens {
    top: calc(50% - 5px);
  }
  .col-xl-8 {
    max-width: 66.66667%;
    flex: 0 0 66.66667%;
    -ms-flex: 0 0 66.66667%;
  }
  .col-xl-offset-2 {
    margin-left: 16.66667%;
  }
}
@media screen and (min-width: 1440px) {
  header .container-header {
    width: 1440px;
  }
  .container {
    width: 1440px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .visible-sm.wrapper-mobile-header {
    display: flex !important;
  }
  .visible-sm {
    display: block !important;
  }
  .hidden-sm {
    display: none !important;
  }
  .header_logoa {
    top: 156px;
  }
}
@media (max-width: 1400px) {
  header .inside-left-menu .internal-structure .internal-primary-menu ul {
    padding-top: 0;
  }
  header .inside-left-menu .internal-structure .internal-primary-menu li {
    height: 28px;
  }
}
@media (max-width: 1399px) {
  .main-container .row-space,
  footer .row-space {
    padding: 0 45px;
  }
  .main-container .row-space,
  footer .row-space {
    padding: 0 45px;
  }
  footer .second-footer ul li {
    padding-left: 0;
    padding-right: 0;
  }
  footer .second-footer ul li a {
    font-size: 12px;
    padding-right: 10px;
  }
  footer .second-footer p {
    font-size: 12px;
    padding-left: 30px;
  }
  header .container-header .header-space {
    padding: 0 45px;
  }
  header .inside-left-menu .internal-structure {
    padding: 77px 60px 20px 60px;
  }
  #login-register #wrapper-login-register {
    padding: 0 6rem;
  }
  #login-register #wrapper-login-register .p-modal-close {
    right: 60px;
  }
  #bag .wrapper-bag {
    padding: 0 6rem;
  }
}
@media (max-width: 1199px) {
  #login-register #wrapper-login-register {
    max-width: 100%;
  }
  #bag .wrapper-bag {
    max-width: 100%;
  }
}
@media (max-width: 1100px) {
  .window-drch-izq.window-small.window-drch-izq {
    width: 50%;
  }
}
@media (max-width: 1024px) {
  header .inside-left-menu .internal-structure .internal-primary-menu li,
  header .inside-left-menu .internal-structure .internal-primary-menu a,
  header .inside-left-menu .internal-structure .internal-primary-menu span {
    line-height: 24px;
    font-size: 26px;
  }
  .big-title,
  .result-text {
    font-size: 46px;
  }
  .finish .dialog-position {
    font-size: 30px;
    padding: 0px;
  }

  .num {
    font-size: 32px;
  }
  .limit-text,
  .result-text-2 {
    font-size: 27px;
  }
  .footerimage {
    height: 200px;
  }
  .content-center {
    padding-top: 180px;
  }
  .header_img {
    width: 300px;
  }
  .header_logoa {
    top: 155px;
  }
  .stop-game {
    padding-top: 180px;
  }
}
@media (max-width: 1023px) {
  header .inside-left-menu .internal-structure .internal-primary-menu li {
    margin-bottom: 15px;
  }
  header
    .inside-left-menu
    .internal-structure
    .internal-primary-menu
    li
    .internal-submenu
    ul {
    padding-top: 4px;
  }
  header .inside-left-menu .internal-structure .internal-account-menu {
    margin-top: 25px;
  }
  header .inside-left-menu .internal-structure .internal-account-menu ul li,
  header .inside-left-menu .internal-structure .internal-secondary-menu ul li {
    margin-bottom: 0;
    line-height: 24px;
  }
  header .inside-left-menu .internal-structure .internal-account-menu ul li a,
  header
    .inside-left-menu
    .internal-structure
    .internal-secondary-menu
    ul
    li
    a {
    line-height: 24px;
  }
}
@media (max-width: 991px) {
  header .container-header {
    height: 99px;
  }
  .main-container .row-space,
  footer .row-space {
    padding: 0 30px;
  }
  footer .footer-principal {
    padding-top: 35px;
  }
  .home-newsletter-title h2.block-title {
    font-size: 22px;
    line-height: 26px;
    margin-top: 15px;
  }
  .newsletter p {
    text-align: left;
    font-size: 13px;
  }
  .home-newsletter-title p {
    text-align: center;
  }
  .newsletter .flex {
    margin-bottom: 10px;
  }
  .newsletter input {
    width: 100%;
    font-size: 13px;
  }
  .newsletter .formErrorMessage {
    text-align: left;
    font-size: 13px;
  }
  .main-container .row-space,
  footer .row-space {
    padding: 0 30px;
  }
  footer .socialiconsaccess-content {
    text-align: center;
    margin: 35px auto;
  }
  footer .services-footer,
  footer .about-us-footer,
  footer .profiling-footer {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  footer .services-footer {
    border-top: 1px solid rgba(151, 151, 151, 0.35);
  }
  footer .services-footer,
  footer .about-us-footer,
  footer .profiling-footer {
    padding: 16px 45px;
    border-bottom: 1px solid rgba(151, 151, 151, 0.35);
    margin: 0 -45px;
  }
  footer .services-footer h3,
  footer .about-us-footer h3,
  footer .profiling-footer h3 {
    cursor: pointer;
  }
  .container.footer-principal h3 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  footer .accordion-arrow-footer {
    display: block;
  }
  footer .services-footer .accordion-arrow-footer,
  footer .about-us-footer .accordion-arrow-footer,
  footer .profiling-footer .accordion-arrow-footer {
    margin-top: 10px;
  }
  footer .services-footer li,
  footer .about-us-footer li,
  footer .profiling-footer li {
    padding-bottom: 3px;
  }
  footer .services-footer a,
  footer .about-us-footer a,
  footer .profiling-footer a {
    text-transform: uppercase;
  }
  footer .second-footer ul li {
    display: block;
    padding-bottom: 0;
  }
  footer .second-footer ul li a {
    font-size: 13px;
  }
  footer .second-footer p {
    padding-left: 0;
    font-size: 13px;
    text-align: left;
    margin-top: 0;
  }
  footer .second-footer {
    border-top: none;
    margin-top: 15px;
  }
  footer .second-footer ul {
    margin-top: 0;
  }
  header .container-header .header-space {
    margin-bottom: 0;
    border-top: 1px solid rgba(151, 151, 151, 0.35);
    margin-top: 5px;
    padding: inherit;
  }
  header ul.primary-menu {
    padding-top: 0;
    width: 343px;
    margin: 0 auto;
  }
  header .inside-left-menu {
    min-width: 50%;
    border-top: 1px solid #cacaca;
  }
  header .inside-left-menu .internal-structure {
    padding: 74px 45px 20px 45px;
  }
  header .inside-left-menu .internal-structure .internal-primary-menu {
    width: 310px;
  }
  header .inside-left-menu .internal-structure .internal-primary-menu ul {
    padding-top: 2px;
  }
  header .inside-left-menu .internal-structure .internal-primary-menu li {
    margin-bottom: 13px;
    height: 26.47px;
  }
  header
    .inside-left-menu
    .internal-structure
    .internal-primary-menu
    li
    .internal-submenu
    ul {
    padding-top: 9px;
  }
  header .inside-left-menu .internal-structure .internal-account-menu ul li a,
  header
    .inside-left-menu
    .internal-structure
    .internal-secondary-menu
    ul
    li
    a {
    line-height: 28px;
  }
  #login-register #wrapper-login-register {
    margin-top: 35px;
  }
  #login-register #wrapper-login-register .p-modal-close {
    right: 45px;
  }
  .login-register-header p {
    font-size: 13px;
    line-height: 24px;
  }
  .login-register-header h3,
  .login-register-header .as-h3-custom {
    font-size: 18px;
    line-height: 25px;
  }
  #bag .wrapper-bag {
    padding: 0 4.5rem;
  }
  #bag .wrapper-bag .p-modal-close {
    right: 45px;
  }
  .window-drch-izq.window-small.window-drch-izq {
    padding-right: 45px !important;
  }
  body.hasBanner #window-search.window-drch-izq.window-small {
    top: 143px !important;
  }
}
@media (max-width: 900px) {
  header .inside-left-menu .internal-structure .internal-account-menu {
    margin-top: 84px;
  }
  .stop-game .container {
    padding: 15px;
  }
  .stop-text {
    padding: 30px 15px 60px;
  }
}
@media (max-width: 820px) {
  .big-title,
  .result-text {
    font-size: 43px;
  }
  .limit-text,
  .result-text-2 {
    font-size: 25px;
  }
  .num {
    font-size: 30px;
  }
  .game-trigger {
    padding-top: 0;
  }
  .canvas-wrapper {
    padding-bottom: 0;
  }
  .result-buttons {
    padding-top: 30px;
  }
  .van + .button-wrap {
    padding-top: 0;
  }
  .content {
    height: 600px;
  }
  .header_logoa {
    top: 145px;
  }
  #root {
    position: relative;
  }
  .content_wrap:before,
  .content_wrap:after {
    width: 40%;
  }
  .gift_wrapper {
    right: 30px;
  }
}
@media (max-width: 820px) and (orientation: landscape) {
  .game-result {
    padding: 0;
  }
  .content {
    height: auto;
  }
  .footerimage {
    height: 100px;
  }
  .badge {
    margin-left: -60px;
  }
  .arrows {
    bottom: 220px;
  }
}
@media (max-width: 767px) {
  .main-container .row-space,
  footer .row-space {
    padding: 0 6px;
  }
  .main-container .row-space,
  footer .row-space {
    padding: 0 6px;
  }
  footer .services-footer,
  footer .about-us-footer,
  footer .profiling-footer {
    padding: 15px 21px;
    margin: 0 -21px;
  }
  footer ul li span a {
    font-size: 14px;
  }
  .container-header .wrapper-mobile-header.visible-xs {
    display: flex !important;
  }
  .hidden-xs {
    display: none !important;
  }
  .visible-xs {
    display: block !important;
  }
  header .inside-left-menu {
    width: 100%;
  }
  header .inside-left-menu .internal-structure {
    padding: 66px 21px 20px 21px;
    width: 100%;
  }
  header .inside-left-menu .internal-structure .close-button-menu {
    display: block;
  }
  header .inside-left-menu .internal-structure .back-button {
    top: 23px;
    z-index: 5;
    width: auto;
    height: auto;
    position: absolute;
    transition: left 1s;
    left: -150%;
    padding-left: 30px;
    background-repeat: no-repeat;
    background-position: center left;
    display: block;
    cursor: pointer;
  }
  header .inside-left-menu .internal-structure .back-button span {
    color: #222;
    font-size: 17px;
    font-family: "CormorantGaramond";
    font-weight: 600;
    text-transform: uppercase;
  }
  header .inside-left-menu .internal-structure .internal-primary-menu {
    width: 100%;
  }
  header .inside-left-menu .internal-structure .internal-primary-menu li {
    margin-bottom: 14.5px;
    height: 26.47px;
  }
  header
    .inside-left-menu
    .internal-structure
    .internal-primary-menu
    li
    .angle-right {
    opacity: 1;
  }
  header
    .inside-left-menu
    .internal-structure
    .internal-primary-menu
    li
    .internal-submenu {
    left: -100vw;
    display: block;
    padding: 66px 46px 60px 0px;
  }
  header
    .inside-left-menu
    .internal-structure
    .internal-primary-menu
    li
    .internal-submenu
    ul {
    padding-top: 8px;
  }
  header
    .inside-left-menu
    .internal-structure
    .internal-primary-menu
    li
    .internal-submenu
    ul {
    padding-top: 8px;
  }
  header
    .inside-left-menu
    .internal-structure
    .internal-primary-menu
    li
    .internal-submenu
    ul
    li
    a,
  header
    .inside-left-menu
    .internal-structure
    .internal-primary-menu
    li
    .internal-submenu
    ul
    li
    span {
    font-size: 26px;
  }
  header .inside-left-menu .internal-structure .internal-account-menu {
    margin-top: 60px;
  }
  header .inside-left-menu .internal-structure .internal-account-menu ul li,
  header .inside-left-menu .internal-structure .internal-secondary-menu ul li {
    margin-bottom: 5px;
  }
  header .angle-right {
    float: right;
    margin-left: 6px;
    margin-right: -3px;
    width: 16px;
    height: 18px;
    background-size: 60%;
  }
  .close-button .p-modal-close {
    right: 21px;
    margin-top: 21px;
  }
  #login-register #wrapper-login-register .p-modal-close {
    right: 21px;
  }
  #bag .wrapper-bag {
    padding: 0 21px;
  }
  #bag .wrapper-bag .p-modal-close {
    right: 21px;
  }
  .window-drch-izq.window-small.window-drch-izq {
    width: 100%;
    padding-right: 21px !important;
  }
}
@media (max-width: 740px) {
  .guide {
    position: relative;
    top: auto;
    transform: none;
    max-width: 123px;
    display: inline-block;
  }
  .sprouts {
    right: auto;
    margin: 0;
    padding-left: 30px;
  }
  .gifts-icon {
    left: auto;
    margin: 0;
    padding-right: 30px;
  }
  .guide span {
    width: auto;
    margin: 0 0 10px 0;
    text-align: center;
  }
  .game-trigger .button {
    margin-top: 30px;
    display: block;
  }
  .home-text + .button-wrap {
    padding: 0;
  }
  .game-result .button-wrap {
    display: inline-block;
  }
  .game-result .button {
    display: block;
    margin: 0 auto;
  }
  .game-result .button + .button {
    margin: 30px auto 0;
  }
  .badge {
    margin-left: -90px;
  }
}
@media (max-width: 551px) {
  header .inside-left-menu .internal-structure .internal-account-menu {
    margin-top: 45px;
  }
  .music {
    left: 10px;
    bottom: -20px;
    overflow: hidden;
  }
  .music img {
    display: none;
  }
  .game .gifts {
    right: 10px;
    bottom: -10px;
  }
  .game .gifts > * {
    display: inline-block;
  }
  .game .gifts .num {
    height: 29px;
    line-height: 29px;
  }
  .game .gifts .label {
    margin-top: 0;
    display: none;
  }
  .remove-header .main {
    padding-top: 20px;
  }
  .home-text {
    font-size: 18px;
    padding-bottom: 0;
  }
  .silver-level .badge {
    left: auto;
    right: 0;
    top: -20px;
  }
}
@media (max-width: 550px) {
  header .inside-left-menu .internal-structure .internal-primary-menu li,
  header .inside-left-menu .internal-structure .internal-primary-menu a,
  header .inside-left-menu .internal-structure .internal-primary-menu span {
    font-size: 23px;
  }
}
@media (max-width: 600px) and (orientation: landscape) {
  .badge {
    margin-left: -100px;
  }
}
@media (max-width: 478px) {
  header
    .inside-left-menu
    .internal-structure
    .internal-primary-menu
    li
    .internal-submenu {
    left: -478px;
  }
}
@media (max-width: 450px) {
  header ul.primary-menu {
    width: auto;
  }
}
@media (max-width: 420px) {
  .landingimage {
    background-image: url("../assets/old-game/landing_mobile.jpg");
  }
  .button-wrap {
    padding-top: 25px;
  }
  .ribbon {
    line-height: 0;
    padding-bottom: 20px;
  }
  .guide span {
    line-height: 1;
  }
  .gifts-icon img {
    height: 48px;
  }
  .sprouts img {
    height: 56px;
  }
  .ribbon img {
    height: 75px;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  .big-title,
  .result-text {
    font-size: 40px;
  }
  .button {
    font-size: 16px;
    padding: 10px 20px;
  }
  .limit-text,
  .result-text-2,
  .game-result .limit-text {
    font-size: 21px;
  }
  .num {
    font-size: 26px;
  }
  .content-center {
    padding: 120px 50px 120px;
  }
  .game-result {
    padding: 0;
  }
  .game-result .van {
    padding-top: 25px;
  }
  .finish .dialog-position {
    font-size: 27px;
  }
  .result-text .gifts {
    display: block;
  }
  .limit-text {
    line-height: 1.3;
    padding: 0;
  }
  .content {
    height: auto;
  }
  .footerimage {
    background: url("../assets/old-game/mobilefooter.png") no-repeat;
    height: 130px;
    background-size: cover;
    bottom: 94px;
  }
  .content_wrap:before {
    background: url("../assets/old-game/bgmobile.png") no-repeat 0 100%;
    transform: none;
    right: auto;
    left: 0;
    width: 100%;
    height: 323px;
    background-size: cover;
    bottom: 55px;
  }
  .content_wrap:after {
    content: none;
  }
  .noterms .content_wrap:before {
    bottom: 0;
  }
  .noterms .footerimage {
    bottom: 0;
    height: 300px;
  }
  .gift_wrapper {
    right: 50%;
    transform: translateX(50%);
    padding: 8px 27px;
    width: 180px;
    text-align: center;
  }
  .header_logoa {
    top: 130px;
  }
  .home-text,
  .stop-text {
    line-height: 1.2;
  }
  .stop-game {
    padding-top: 130px;
  }
  .arrows {
    bottom: 350px;
  }
  .arrow-text {
    color: #436749;
  }
  .canvas-wrapper {
    padding-bottom: 90px;
  }
  .badge {
    width: 80px;
    margin-left: -60px;
  }
}
@media (max-width: 375px) {
  .finish .dialog-position {
    font-size: 24px;
  }
  .big-title,
  .result-text {
    font-size: 37px;
  }
}
@media (max-width: 320px) {
  .canvas-wrapper {
    padding-bottom: 100px;
  }
  .noterms .footerimage {
    height: 200px;
  }
}
@media (max-height: 700px) {
  .ribbon {
    padding-bottom: 15px;
  }
  .result-text {
    line-height: 1.2;
  }
  .game-result .button + .button {
    margin-top: 20px;
  }
  .result-buttons {
    padding-top: 20px;
  }
  .limit-text {
    font-size: 21px;
  }
}
